import { Browser } from '@capacitor/browser';
import React, { useEffect } from 'react';

const CountdownToClose = () => {
   

  return (
    <div>
      <h1>Redirecting...</h1>
      <p>Closing the browser shortly...</p>
    </div>
  );
};

export default CountdownToClose;
