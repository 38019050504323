import { default as http } from "../rest/http";

export const spendingAdjustment = async () => {
  try {
    const response = await http.get("/transaction-service/spending/adjustments");
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};


export const editSpendingLimitService = async (data:any) => {
  try {
    const response = await http.post("/transaction-service/spending/limits",data);
    return response;
  } catch (error) {
    console.error("Error saving account:", error);
    throw error;
  }
};
