import { FC, useEffect, useRef } from "react";
interface RightDrawerProps {
  isOpen: boolean; // Controlled state for the drawer open/close
  onClose: () => void; // Callback to close the drawer
  children: React.ReactNode; // Dynamic content to be passed inside the drawer
}
const RightDrawer: FC<RightDrawerProps> = ({ isOpen, onClose, children }) => {
  const drawerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Close the drawer if clicked outside of the drawer
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      // Add event listener to detect outside click when the drawer is open
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Cleanup the event listener when the drawer is closed
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);
  return (
    <div >
      <div ref={drawerRef}
        id="drawer-right"
        className={`fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform transform ${isOpen ? "translate-x-0" : "translate-x-full"
          } bg-white w-80 dark:bg-gray-800`}
        tabIndex={-1}
        aria-labelledby="drawer-right-label"
      >
        <button
          onClick={onClose} // Close drawer when the close button is clicked
          type="button"
          aria-controls="drawer-right"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close menu</span>
        </button>

        {/* Dynamic Content inside the drawer */}
        <div className="mt-10">{children}</div>
      </div>
    </div>
  );
};


export default RightDrawer;
