export const SpendingLimitTitleMessage = ({ overSpendingAmount, recommendedAmount }: { overSpendingAmount: number , recommendedAmount: number}) => {
    const isOverspending = overSpendingAmount < 0;
    const displayAmount = Math.abs(recommendedAmount).toFixed(2);
  
    return (
      <div>
        {isOverspending ? (
          <div>
            I found a few adjustments that will reduce your spending by ${displayAmount}. 
          </div>
        ) : (
          <div>
            I found a few adjustments that will increase your cash flow by ${displayAmount}.
          </div>
        )}
      </div>
    );
  };
  