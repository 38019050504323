import { createSlice } from "@reduxjs/toolkit";
import { editSpendingLimitAsync, spendingAdjustmentsAsync } from "../thunks/SpendingThunk";

export const STATUS = {
    LOADING: "loading",
    SUCCESS: "success",
    IDLE: "idle",
    FAILED: "failed",
};

export interface FinleyCategoryData {
    finleyCategory: string;
    finleyCategoryName: string;
    originalAmount: number;
    recommendedAmount: number;
    recommendedPercentage: number;
    savings: number;
    drasticallyAdjusted: boolean;
}

interface SpendingAdjustmentsState {
    spendingAdjustmentsResponse: FinleyCategoryData[] | null; 
    spendingAdjustmentsStatus: string; 
    editSpendingResponse:any|null;
    editSpendingStatus:string;
    error: string | null; 
}

const initialState: SpendingAdjustmentsState = {
    spendingAdjustmentsResponse: null,
    spendingAdjustmentsStatus: STATUS.IDLE,
    editSpendingResponse:null,
    editSpendingStatus:STATUS.IDLE,
    error: null,
};

const SpendingAdjustmentsSlice = createSlice({
    name: "spendingAdjustments",
    initialState,
    reducers: {
        resetSpendingStatus: (state) => {
            state.spendingAdjustmentsStatus = STATUS.IDLE;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(spendingAdjustmentsAsync.pending, (state) => {
                state.spendingAdjustmentsStatus = STATUS.LOADING;
                state.error = null;
            })
            .addCase(spendingAdjustmentsAsync.fulfilled, (state, action) => {
                state.spendingAdjustmentsResponse = action.payload;
                state.spendingAdjustmentsStatus = STATUS.SUCCESS;
            })
            .addCase(spendingAdjustmentsAsync.rejected, (state, action) => {
                state.spendingAdjustmentsStatus = STATUS.FAILED;
                state.error = action.error.message ?? "An error occurred.";
            })
            
            .addCase(editSpendingLimitAsync.pending, (state) => {
                state.editSpendingStatus = STATUS.LOADING;
                state.error = null;
            })
            .addCase(editSpendingLimitAsync.fulfilled, (state, action) => {
                state.editSpendingResponse = action.payload;
                state.editSpendingStatus = STATUS.SUCCESS;
            })
            .addCase(editSpendingLimitAsync.rejected, (state, action) => {
                state.editSpendingStatus = STATUS.FAILED;
                state.error = action.error.message ?? "An error occurred.";
            });


    },
});

export const { resetSpendingStatus } = SpendingAdjustmentsSlice.actions;
export default SpendingAdjustmentsSlice.reducer;
