import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store'; // Ensure you import the correct RootState type
import { AccountInfo } from '../../types/CommonType';
import { CardFooter } from '../ui/Card';
import { BanknoteIcon } from 'lucide-react';

interface FilteredAccountsProps {
  filterTypes: string[]; // Array of types e.g. ['depository', 'checking']
}

const FilteredAccounts: React.FC<FilteredAccountsProps> = ({ filterTypes }) => {
  const { accounts } = useSelector((state: RootState) => state.TransactionStore);
  
  // Use filter to get accounts based on the provided filterTypes
  const filteredAccounts = (!filterTypes || filterTypes.length === 0)
    ? accounts // No filter or empty filter, return all accounts
    : accounts?.filter((account: AccountInfo) => filterTypes.includes(account.type)) || [];


  return (
    <>
      {filteredAccounts && filteredAccounts.length > 0 && (
        <CardFooter>
          <div className="flex flex-row w-full">
            <div>
              {filteredAccounts.map((account: AccountInfo) => (
                <div
                  key={account.id}
                  className="bg-customGray rounded-lg p-3 mb-4 shadow-sm grid grid-cols-6 gap-4"
                >
                  {/* Column 1: Image */}
                  <div className="col-span-1 flex items-center justify-center">
                    {account.institution?.logo_content ? (
                      <img
                        src={`data:image/png;base64,${account.institution.logo_content}`}
                        alt={`${account.institution.institution_name} logo`}
                        className="bank-logo"
                      />
                    ) : (
                      <BanknoteIcon className="text-customBlue size-9"></BanknoteIcon>
                    )}

                  </div>

                  {/* Column 2: Account Info */}
                  <div className="col-span-5 flex flex-col justify-center">
                    <p className="text-darkBlue font-serif text-base font-normal leading-6">{account.official_name}</p>
                    <span className="text-neutral500 font-inter text-xs font-medium leading-5">( ... {account.mask})</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </CardFooter>
      )}
    </>
  );
};

export default FilteredAccounts;
