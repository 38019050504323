import { createSlice } from "@reduxjs/toolkit";
import { submitSurveyAsync } from "../thunks/SurveyThunk";
import { ChatAsync, FetchConversationsAsync } from "../thunks/ChatThunk";


// Defining possible status values
export const STATUS = {
    LOADING: "loading",
    SUCCESS: "success",
    IDLE: "idle",
    FAILED: "failed",
};
// Interface for a single transaction
export interface Transaction {
    id: string;
    user_id: string;
    title: string;
    created_at: string; // ISO date string
    updated_at: string; // ISO date string
  }
  
  // Interface for the transaction response with pagination
 export interface conversations {
    total_items: number;
    page: number;
    per_page: number;
    total_pages: number;
    transactions: Transaction[];
  }
  
// Defining the initial state structure
interface ChatInitialstate {
    chatRespone: null;
    chatConversations: conversations | null;
    chatConversationsStatus: string;
    chatMessages:null;
    chatStatus: string;
    error: string | null;
    chatHomePageMessage:string|null
}

// Setting up the initial state
const initialState: ChatInitialstate = {
    chatRespone: null, // No login response initially
    chatConversations: null,
    chatConversationsStatus: STATUS.IDLE,
    chatMessages:null,
    chatStatus: STATUS.IDLE, // Default status is idle
    error: null, // No error at the beginning
    chatHomePageMessage:null
};

const ChatSlice = createSlice({
    name: "Chat",
    initialState,
    reducers: {
        resetChatStatus: (state) => {
            state.chatStatus = STATUS.IDLE;
        },
        setChatMessage:(state, action:{payload:string})=>{
            state.chatHomePageMessage = action.payload
        },
        resetChatMessage:(state)=>{
            state.chatHomePageMessage = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ChatAsync.pending, (state) => {
                state.chatStatus = STATUS.LOADING;
                state.error = null;
            })
            .addCase(ChatAsync.fulfilled, (state, action) => {
                state.chatRespone = action.payload;
                state.chatStatus = STATUS.SUCCESS;
            })
            .addCase(ChatAsync.rejected, (state, action) => {
                state.chatStatus = STATUS.FAILED;
                state.error = action.error.message ?? null;
            })

            .addCase(FetchConversationsAsync.pending, (state) => {
                state.chatConversationsStatus = STATUS.LOADING;
                state.error = null;
            })
            .addCase(FetchConversationsAsync.fulfilled, (state, action) => {
                state.chatConversations = action.payload;

                state.chatConversationsStatus = STATUS.SUCCESS;
            })
            .addCase(FetchConversationsAsync.rejected, (state, action) => {
                state.chatConversationsStatus = STATUS.FAILED;
                state.error = action.error.message ?? null;
            });
    },
});

// Exporting the actions and reducer
export const { resetChatStatus, setChatMessage, resetChatMessage } = ChatSlice.actions;
export default ChatSlice.reducer;
