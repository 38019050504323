// import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { useState } from "react";
import Chat from "../chat/Chat";
import { HomeTabSection } from "./HomeTabSection";
import { OverView } from "../Spendings/OverView";

const Home = () => {
    const [activeTab, setActiveTab] = useState('Home'); // Track the active tab
    const selectTab = (tabName: string) => {
        setActiveTab(tabName);
    }
    return (
        <div className='lg:w-[393px] lg:mx-auto'>
        <div className="flex flex-col min-h-screen ">
            {/* Header Section */}
            {/* {activeTab !== 'Finley AI' &&
                <div className="px-6">
                    <Header />
                </div>} */}


            {activeTab !== 'Finley AI' && (
                <div className="flex-grow   overflow-auto">
                    {/* Add your main content here */}
                    {activeTab === 'Home' && <div className='p-3'> <HomeTabSection selectTab={selectTab} ></HomeTabSection></div>}
                    {activeTab === 'Spending' && <p><OverView></OverView></p>}
                    {activeTab === 'Meet Coach' && <p></p>}
                </div>
            )}
            {activeTab === 'Finley AI' && <Chat isLoadedFromHomeScreen={true} selectTab={selectTab}/>}

            {activeTab !== 'Finley AI' && (
            <Footer selectTab={selectTab} />)}
        </div>
        </div>
    );
};

export default Home;
