import { createSlice } from "@reduxjs/toolkit";
import { getUserAccountDetailsAsync } from "../thunks/MasterThunk";
import { STATUS } from "../../types/CommonType";

interface UserAccount {
  email: string | null;
  email_verified: boolean;
  external_unique_id: string | null;
  id: string;
  job_title: string | null;
  first: string | null;
  last: string | null;
  middle: string | null;
  mobile_phone: string | null;
  organization_id: string | null;
  user_role: string | null;
}

interface MasterState {
  userAccount: UserAccount;
  status: string;
  error: string | null;
}

const initialState: MasterState = {
  userAccount: {
    email: null,
    email_verified: false,
    external_unique_id: null,
    id: "",
    job_title: null,
    first: null,
    last: null,
    middle: null,
    mobile_phone: null,
    organization_id: null,
    user_role: null,
  },
  status: STATUS.IDLE,
  error: null,
};

const MasterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {
    // Define reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAccountDetailsAsync.pending, (state) => {
        state.error = null;
        state.status = STATUS.LOADING;
      })
      .addCase(getUserAccountDetailsAsync.fulfilled, (state, action) => {
        state.status = STATUS.SUCCESS;
        state.userAccount = action.payload as UserAccount; // Ensure payload matches `UserAccount`
      })
      .addCase(getUserAccountDetailsAsync.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.error.message ?? null;
      });
  },
});

export default MasterSlice.reducer;
