
import { default as http } from "../rest/http";
export const getUserAccountDetails = async () => {
    try {
      const response = await http.get("/master-data/account");
      return response;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }
  