
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserAccountDetails } from "../../services/MasterService";
export const getUserAccountDetailsAsync = createAsyncThunk(
    "user/getUserAccountDetails",
    async () => {
      const response: any = await getUserAccountDetails();
      return response;
    }
  );
  