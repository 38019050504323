
import { categoriesImage } from "../../common/categories";
export interface CategoryComponentProps {
    categoryName: string,
    amount: string,
    imageName: string
}

export function CategoryComponent({ categoryName, amount, imageName }: CategoryComponentProps) {
    const category = categoriesImage.find(
        (cat) => cat.categoryName.toLowerCase() === imageName.toLowerCase()
      );
    
      const icon = category?.icon || "";
    return (
        <div className="flex p-5 items-start gap-3 self-stretch rounded-2xl border border-neutral-200 bg-white mb-3">
            <div className="flex items-center gap-3 flex-1">
                {icon}
                <div className="flex justify-between items-center flex-1">
                    <div className='text3'>{categoryName}</div>
                    <div className='text3'>${amount}</div>
                </div>
            </div>
        </div>
    );
}
