
export const SpendingBar = ({ expenses, totalExpense }: { expenses: any[]; totalExpense: number }) => (
    <div className="flex w-full h-[15px] overflow-hidden rounded-l-lg rounded-r-lg">
      {expenses.map((expense, index) => {
        const widthPercentage = (expense.amount / totalExpense) * 100;
    
        return (
          <div
            key={expense.category + index}
            className={`${expense.color}  h-full `}
            style={{ width: `${widthPercentage}%` }}
          />
        );
      })}
    </div>
  );
  