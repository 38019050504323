
  export const categoriesImage = [
   { categoryName: "housing_and_utilities", icon: "🏠" }, 
   { categoryName: "education_childcare", icon: " 🎓" },
   { categoryName: "transportation", icon: "🚗" },
   { categoryName: "healthcare_medical", icon: "🏥" },
   { categoryName: "insurance", icon: "🛡️" },
   { categoryName: "loans_and_payments", icon: "💳" },
   { categoryName: "loans_and_credit_cards", icon: "💳" },
   { categoryName: "groceries", icon: "🛒" },
   { categoryName: "miscellaneous", icon: "⚙️" },
   { categoryName: "entertainment_and_recreation", icon: "🎈" },
   { categoryName: "entertainment", icon: "🎈" },
   { categoryName: "dining_out", icon: "🍴" },
   { categoryName: "shopping", icon: "🛍️" },
   { categoryName: "travel", icon: "✈️" },
 ];