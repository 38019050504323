import { Card, CardContent } from "../ui/Card";
import PlaidLinkComponent from "../plaid/PlaidLinkComponent";
import '../../App.css';
import Header from "../common/Header";
import FilteredAccounts from "./FilteredAccounts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AppDispatch, RootState } from "../../store/store";
import { useEffect } from "react";
import { fetchAccountDetailAsync } from "../../store/thunks/TransactionThunk";
import RightArrow from '../../assets/RightArrow.svg';
import useDeepLinking from "../../hooks/useDeepLinking";

export default function AccountDetails() {
  const dispatch = useDispatch<AppDispatch>();
  const { accounts } = useSelector((state: RootState) => state.TransactionStore);
  const navigate = useNavigate();
  useDeepLinking();
  useEffect(() => {
    const fetchAccounts = async () => {
      await dispatch(fetchAccountDetailAsync());
    };

    fetchAccounts();
  }, [dispatch]);

  function handleClick(): void {
    navigate('/survey')
  }

  return (
    <>
      <div className="lg:container lg:max-w-md h-screen flex flex-col bg-customBgColor">
        <Header />

        {/* Content area with flex-grow */}
        <div className="flex-grow overflow-y-auto p-3">
          <div className="flex mb-4 space-x-4">

            <div className="font-serif flex-col text-base font-normal leading-6 text-left">
              <div className="font-serif text-xl font-bold leading-7 tracking-tightCustom">Let's connect your account!</div>
              <div className="text-darkBlue font-serif text-base font-normal leading-6 pt-3">Please connect the account(s) that you use for your everyday purchases and bills so that I can help you manage your spending habits.</div>
            </div>
          </div>

          {/* Cards with space between */}
          <div className="space-y-4">
            <Card
              className={`bg-white rounded-2xl w-full overflow-hidden transition-all duration-300 ${!accounts || (accounts && accounts.length === 0 )? 'h-[82px]' : 'min-h-[82px]'}`}
            >
              <CardContent className="pt-3">
                <div className="flex justify-between items-center">
                  <div className="text-center font-serif font-bold text-lg">Connect Accounts</div>
                  <PlaidLinkComponent />
                </div>
              </CardContent>
              {accounts && accounts.length > 0 && (
                <FilteredAccounts filterTypes={[]} />
              )}
            </Card>

            {/* Show this message if no accounts */}
            {accounts && accounts.length === 0 && (
              <div className="w-full text-darkBlue text-center font-inter text-[12px] font-medium leading-[20px]">
                At least one primary spending account is required.
              </div>
            )}
          </div>

        </div>

        {/* Sticky footer button */}
        <div className="sticky bottom-0 w-full bg-customBgColor py-3 px-3">
          {accounts && accounts?.length > 0 && (
            <button
              onClick={handleClick}
              className="flex justify-center items-center cursor-pointer w-full bg-loginBg text3 text-white px-8 py-2 rounded-full"
             
            >
              <img
                src={RightArrow}
                className="size-4 mr-2"
                alt="Arrow"
              />
              {`Continue with ${accounts?.length} ${accounts.length === 1 ? "account" : "accounts"}`}
            </button>
          )}
        </div>
      </div>
    </>
  );
}
