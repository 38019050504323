const InfoIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <g clip-path="url(#clip0_2419_1489)">
        <path d="M7.99992 14.8667C11.6818 14.8667 14.6666 11.8819 14.6666 8.19999C14.6666 4.51809 11.6818 1.53333 7.99992 1.53333C4.31802 1.53333 1.33325 4.51809 1.33325 8.19999C1.33325 11.8819 4.31802 14.8667 7.99992 14.8667Z" stroke="#525252" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 10.8666V8.19995" stroke="#525252" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 5.53333H8.00667" stroke="#525252" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_2419_1489">
          <rect width="16" height="16" fill="white" transform="translate(0 0.199951)"/>
        </clipPath>
      </defs>
    </svg>
    )
}
export default InfoIcon;