import React from "react";

const Ellipse: React.FC<{ amount: string, title: string }> = ({ amount, title }) => {
  return (
    <div
      className="flex items-center justify-center rounded-full bg-green-50"
      style={{
        width: "177.563px",
        height: "178px",
      }}
    >
       <div className="flex flex-col">
        <span className="text-green-600 intertextxs">{title}</span>
       <span className="text-green-600 texth1medium">{amount}</span>
       <span className="text-green-600 intertextxs">monthly</span>
       </div>
     
    </div>
  );
};

export default Ellipse;
