import { useState } from 'react';
import { useNavigate } from 'react-router';
import HomeIcon from '../../assets/HomeIcon';
import FinleyAIIcon from '../../assets/FinleyAIIcon';
import SpendingIcon from '../../assets/SpendingIcon';
import MeetCoachIcon from '../../assets/MeetCoachIcon';
type TabName = 'Home' | 'Spending' | 'Meet Coach' | 'Finley AI';
interface FooterProps {
    selectTab: (tabName: TabName|any) => void;
}
function Footer({selectTab}:FooterProps) {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('Home'); 

    const handleTabClick = (tabName: TabName, route?: string): void => {
        selectTab(tabName);
        setActiveTab(tabName);
        if (route) {
            navigate(route);
        }
    };
    const activeStyles = 'border-t-2 border-green-500  text-green ';
    const inactiveStyles= 'text-neutral-400';
    const color ={
        active:'#22C55E',
        inactive:'#525252'
    }
    return (
 
        <div className="sticky bg-white bottom-0 left-0 right-0 h-[80px] mt-3">
            <div className="grid grid-cols-4 w-full max-w-[800px] mx-auto ">
                <div
                    className={`flex flex-col px-4 py-4 items-center ${activeTab === 'Home' ? activeStyles : inactiveStyles}`}
                    onClick={() => handleTabClick('Home')}
                >
              
                    <HomeIcon color= {`${activeTab === 'Home' ? color.active : color.inactive}`}></HomeIcon>

                    <div className=" mt-1 intertextxs">Home</div>
                </div>
                <div
                    className={`flex flex-col px-4 py-4 items-center ${activeTab === 'Spending' ? activeStyles : inactiveStyles}`}
                    onClick={() => handleTabClick('Spending')}
                >
                    <SpendingIcon color= {`${activeTab === 'Spending' ? color.active : color.inactive}`}/>
                    <div className="mt-1 intertextxs">Spending</div>
                </div>
                <div
                    className={`flex flex-col flex-nowrap  px-4 py-4 items-center ${activeTab === 'Meet Coach' ? activeStyles : inactiveStyles}`}
                    onClick={() => handleTabClick('Meet Coach')}
                >
                    <MeetCoachIcon  color= {`${activeTab === 'Meet Coach' ? color.active : color.inactive}`}/>

                    <div className="intertextxs mt-1  text-nowrap">Meet Coach</div>
                </div>
                <div
                    className={`flex flex-col px-4 py-4 items-center ${activeTab === 'Finley AI' ? activeStyles : inactiveStyles}`}
                    onClick={() => handleTabClick('Finley AI')}
                >
                    <FinleyAIIcon color= {`${activeTab === 'Finley AI' ? color.active : color.inactive}`}/>

                    <div className="intertextxs mt-1">Finley AI</div>
                </div>
         
             
            </div>
        </div>


    );
}

export default Footer;
