export const ExpenseMessage = ({ overSpendingAmount }: { overSpendingAmount: number }) => {
  const isOverspending = overSpendingAmount < 0;
  const displayAmount = Math.abs(overSpendingAmount).toFixed(2);

  return (
    <div>
      {isOverspending ? (
        <div>
          You’re currently spending ${displayAmount} over your monthly income. Don’t worry - I’m here to help you get back on track.
        </div>
      ) : (
        <div>
          Great job, you’re currently spending less than you make. I can help you save even more for some extra cash.
        </div>
      )}
    </div>
  );
};
