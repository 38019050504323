import { useState, useEffect } from 'react';
import { Device } from '@capacitor/device';

// Define a TypeScript type for the device information, or use the type from @capacitor/device if provided
interface DeviceInfo {
  model?: string;
  operatingSystem?: string;
  platform?: string;
  uuid?: string;
  // Add other optional properties as needed
}

// Custom hook to get and log device info
const useDeviceInfo = (): DeviceInfo | null => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | null>(null);

  useEffect(() => {
    const logDeviceInfo = async () => {
      try {
        // Retrieve device info using @capacitor/device
        const info = await Device.getInfo();
        setDeviceInfo(info); // Store device info in state
      } catch (error) {
        console.error('Failed to get device info:', error);
      }
    };

    logDeviceInfo();
  }, []);

  return deviceInfo;
};

export default useDeviceInfo;
