
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { getUserAccountDetailsAsync } from '../../../store/thunks/MasterThunk';
import { useSelector } from 'react-redux';
import FinleyLogo from '../../../assets/FinleyLogo28.svg';
import carouselImage from "../../../assets/carouselHome.svg";
import user from "../../../assets/user.svg";
import bell from "../../../assets/bell.svg";
import ChatInput from '../../common/ChatInput';
import { setChatMessage } from '../../../store/slices/ChatSlice';
import { Button } from '@headlessui/react';
import { ArrowRight } from 'lucide-react';
import { fetchAllTransactionsAsync } from '../../../store/thunks/TransactionThunk';
import { useNavigate } from 'react-router';

export interface HomeTabSectionProps {
    selectTab: (tabName: string) => void;
}

export function HomeTabSection({ selectTab }: HomeTabSectionProps) {
    const buttonLabels = [
        'Boost my savings',
        'Help me budget',
        'Cut my spending',
        'Show my spending this month'
    ];
    const dispatch = useDispatch<AppDispatch>();

    const masterData = useSelector((state: RootState) => state.MasterStore);
    const transactionData = useSelector((state: RootState) => state.TransactionStore)
    const navigate = useNavigate();
    const handleSend = (message: string) => {
        dispatch(setChatMessage(message));
        selectTab('Finley AI');
    };
    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getUserAccountDetailsAsync());
        };
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        const fetchTransactionsData = async () => {
            await dispatch(fetchAllTransactionsAsync());
        };
        fetchTransactionsData();
    }, [dispatch]);
    const renderTransactions = () => {
        if (!transactionData?.transactions || transactionData.transactions.length === 0) {
            return <div>No transactions available</div>;
        }
        const limitedTransactions = transactionData.transactions.slice(0, 5);
        return (
            <>
                {limitedTransactions.map((x, index) => (
                    <div
                        key={index}
                        className="w-full flex p-3 justify-between items-center self-stretch bg-slate-100 rounded-lg mb-3"
                    >
                        {/* Merchant Details */}
                        <div className="flex flex-col items-start">
                            <div className="text3">{x.merchant_name || "Unknown Merchant"}</div>
                            <div className="intertextxs text-neutral-500">{x.primary_category || "N/A"}</div>
                        </div>

                        {/* Transaction Details */}
                        <div className="flex flex-col items-end">
                            <div className="text3">
                                {x.amount < 0
                                    ? `- $${Math.abs(x.amount)}`
                                    : `$${x.amount}`}
                            </div>
                            <div className="intertextxs text-neutral-500">{x.transaction_date}</div>
                        </div>
                    </div>
                ))}
            </>
        );
    };


    return (
        <div className=' overflow-x-auto '>
            <div className=" flex pt-10    bg-customBgColor  w-full z-10">


            <div className="flex justify-between items-center w-full px-2 py-2 bg-customBgColor">
                    <div className="flex items-center gap-4">
                        <img className="size-7" src={FinleyLogo} alt="Finley Logo" />
                        <span className="texth2">Hi, {masterData.userAccount.first}</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <button className="h-8 w-8 rounded-full bg-white flex items-center justify-center shadow-md hover:shadow-lg">
                            <img src={bell} alt="notification" />
                        </button>
                        <button className="h-8 w-8 rounded-full bg-white flex items-center justify-center shadow-md hover:shadow-lg">
                            <img src={user} alt="user" />
                        </button>
                    </div>




                </div>

            </div>
            <div className='py-3'>
                <ChatInput isLoading={false} send={handleSend} />
            </div>


            <div className="w-full overflow-x-auto hide-scrollbar">
                <div className="flex space-x-1">
                    {buttonLabels.map((label, index) => (
                        <div key={index} className="flex-shrink-0">
                            <Button
                                onClick={() => handleSend(label)}
                                className="px-4 py-2 border-[0.5px]  border-solid text-darkBlue bg-white rounded-full text3 border-neutral-300"
                            >
                                {label}
                            </Button>
                        </div>
                    ))}
                </div>
            </div>

            <div className="flex mt-3 p-[25px] h-[452px] flex-col gap-[25px] self-stretch rounded-2xl border-[1px] border-solid border-neutral-200 bg-white" >
                {/* Content above the button */}
                <div>
                    <div className=" flex pb-[25px] justify-center h-[224px]"><img src={carouselImage} alt="home" ></img></div>
                    <div className='text2'>Let’s find you some money.</div>
                    <div className='pt-3 text3'>You can free up extra money in your budget with simple steps—it only takes 5 minutes.</div>

                </div>

                {/* Sticky button at the bottom */}
                <div className=" w-full">
                    <button onClick={()=>navigate('/new-connect-account')} className="flex w-full items-center justify-center px-4 py-2 bg-neutral-900 text-white rounded-full space-x-1 text3">
                        <ArrowRight size={16} />
                        <span>Analyze my spending</span>
                    </button>
                </div>
            </div>
            <div className=' flex mt-3  p-[25px]  flex-col flex-grow items-start  self-stretch rounded-2xl border-[1px] border-solid border-neutral-200 bg-white'>
                <div className='textlarge700 pb-3' >Transactions</div>
                {renderTransactions()}
                {/* <div className="flex p-3 justify-between items-center self-stretch">
                    <div className='flex flex-col items-start'>
                        {renderTransactions()}

                    </div>
                    <div></div>
                </div> */}

            </div>

        </div>
    );
}
