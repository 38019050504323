import { useNavigate } from "react-router";
import AppLogo from "../../../assets/AppLogo";
import { CategoryLimitComponent } from "./CategoryLimitComponent";
import Ellipse from "./Ellipse";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { spendingAdjustmentsAsync } from "../../../store/thunks/SpendingThunk";
import { useEffect, useMemo } from "react";
import { Button } from "@headlessui/react";
import { SpendingLimitTitleMessage } from "./SpendingLimitTitleMessage";
import { roundUp } from "../../../utils/helper";

type SpendingLimit = {
    finley_category: string; // or you can use a union type with specific strings if the categories are predefined
    amount: number;
};

type SpendingLimitsResponse = {
    limits: SpendingLimit[];
};
export function SendingLimitsOverview() {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const spendingState = useSelector((state: RootState) => state.TransactionStore.spendingState);
    const { spendingAdjustmentsResponse } = useSelector(
        (state: RootState) => state.spendingStore
    );

    const totalRecommendedAmount = spendingAdjustmentsResponse
        ? spendingAdjustmentsResponse.reduce(
            (sum: number, item: { recommendedAmount: number }) =>
                sum + roundUp(item.recommendedAmount,2),
            0
        )
        : 0;

        const totalSavedAmount = spendingAdjustmentsResponse
        ? spendingAdjustmentsResponse.reduce(
            (sum: number, item: { savings: number }) =>
                sum + roundUp(item.savings,2),
            0
        )
        : 0;

    useEffect(() => {
        const fetchSpendingAdjustments = async () => {
            await dispatch(spendingAdjustmentsAsync());
        };

        fetchSpendingAdjustments();
    }, [dispatch]);
    const spentAmount = (spendingState?.monthlyAvgIncome ?? 0) - (spendingState?.monthlyAvgSpending ?? 0);

    const CategoryLimitList = () => {
        const sortedAdjustments = useMemo(() => {
            return spendingAdjustmentsResponse
                ? [...spendingAdjustmentsResponse].sort((a, b) => b.recommendedAmount - a.recommendedAmount)
                : [];
        }, [spendingAdjustmentsResponse]);

        return (
            <div>
                {sortedAdjustments.map((item) => (
                    <div className="pb-3" key={item.finleyCategoryName}>
                        <CategoryLimitComponent
                            savedAmount={`${roundUp(item.savings,2)}`}
                            percentage={`${item.recommendedPercentage.toLocaleString()}`}
                            categoryName={item.finleyCategoryName}
                            limitAmount={`${roundUp(item.recommendedAmount,2)}`}
                            imageName={item.finleyCategory.toLowerCase()}
                        />
                    </div>
                ))}
            </div>
        );
    };
    return (
        <>
            <div className="container mx-auto lg:w-[393px] lg:mx-auto">
                <div className="flex flex-col min-h-screen ">
                    <div className="flex justify-center items-center mx-auto pt-16 pb-3 h-[104px]">
                        <AppLogo />
                        <div className="ml-2 carouselTitle">
                            <h4>Finley</h4>
                        </div>
                    </div>
                    <div >
                        <div className="flex flex-col p-3 items-start">
                            <div className="textlarge700 mb-3"><SpendingLimitTitleMessage overSpendingAmount={
                                (spendingState?.monthlyAvgIncome ?? 0) - (spendingState?.monthlyAvgSpending ?? 0)
                            } recommendedAmount={totalSavedAmount}></SpendingLimitTitleMessage> </div>
                            <div className="flex p-5 min-h-[452px] flex-col items-center  gap-3 self-stretch rounded-2xl border-[1px] border-solid border-neutral-200 bg-white">
                                <div className="flex  text-center " >  <Ellipse amount={`$${totalSavedAmount.toFixed(2)}`} title={spentAmount < 0 ? "Reduce spending by" : "Increase cash flow by"} /></div>
                                <div className="flex flex-grow">{CategoryLimitList()}</div>

                            </div>

                        </div>
                    </div>
                    <div className="sticky bg-white bottom-0 left-0 right-0 h-[130px] pt-3 w-full pb-[25px] px-[25px] border border-t-[1px] border-neutral-200">
                        <button className="px-4 py-2 text-white bg-neutral-900 rounded-full text3 w-full">
                            Start with these spending limits
                        </button>
                        <div className="pt-2 pb-[30px] text-center text3"><button>Skip for now</button></div>
                    </div>

                </div>
            </div>
        </>
    );
}
