export const ExpenseList = ({ expenses,handleCategoryClick }: { expenses: any[],handleCategoryClick:any }) => (
    <ul className="space-y-2 w-full pt-3">
      {expenses.map((expense, index) => (
 <li key={expense.category} className="flex items-start justify-between text3">
          <div className="flex items-center space-x-2 flex-grow" >
            <span className={`w-4 h-4 ${expense.color} rounded-full inline-block flex-shrink-0`}></span>
            <span className="text3 ">{expense.category}</span>
          </div>
          <div className="ml-auto text-right font-medium ">${expense.amount}</div>
        </li>
      ))}
    </ul>
  );
  