import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import finley from "../../../assets/finley.svg";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { fetchAnalyzeDataAsync } from "../../../store/thunks/TransactionThunk";

export function MessageSequence() {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    const fetchAnalyseData = async () => {
      await dispatch(fetchAnalyzeDataAsync());
    };
    fetchAnalyseData();
  }, [dispatch]);

  // Hardcoded messages
  const messages = [
    "Analyzing your spending...",
    "Reviewing your essential spending...",
    "Reviewing your other spending...",
    "Calculating cash flow...",
  ];

  useEffect(() => {
    if (currentMessageIndex < messages.length) {
      const timer = setTimeout(() => {
        setCurrentMessageIndex((prev) => prev + 1);
      }, 600);

      return () => clearTimeout(timer);
    } else {
      navigate("/overview"); // Navigate to the next screen
    }
  }, [currentMessageIndex, messages.length, navigate]);

  return (
    <div className="flex items-center justify-center h-screen bg-customBgColor">
      <div className="flex flex-col items-center text-center">
        <img src={finley} alt="Finley" className="mb-[25.137px] w-[72px] h-[72px]" />
        <div className="text2">{messages[currentMessageIndex]}</div>
      </div>
    </div>
  );
}
