import React from 'react';

const SpendingIcon: React.FC<{ color: string }> = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path 
                d="M20 4H4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4Z" 
                stroke={color} 
                strokeWidth="1.5" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M10 16H11.5M14.5 16H18" 
                stroke={color} 
                strokeWidth="1.5" 
                strokeMiterlimit="10" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M2 9H22" 
                stroke={color} 
                strokeWidth="1.5" 
                strokeLinecap="square" 
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default SpendingIcon;
