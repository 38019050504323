import { createSlice } from "@reduxjs/toolkit";
import { fetchAccountDetailAsync, fetchAllTransactionsAsync, fetchLinkTokenAsync, fetchPublicAccessTokenAsync, fetchSalaryAsync, postSalaryAsync, fetchAnalyzeDataAsync } from "../thunks/TransactionThunk";
import { STATUS } from "../../types/CommonType";

interface Transaction {
    id: number;
    transaction_id: string;
    account_id: string;
    user_id: string;
    amount: number;
    transaction_date: string;
    iso_currency_code: string;
    payment_channel: string;
    pending: boolean;
    transaction_type: string;
    category_id: string;
    detailed_category: string;
    primary_category: string;
    sub_category: string;
    category_confidence_level: string;
    merchant_name: string;
    name: string;
    website: string | null;
    address: string | null;
    city: string | null;
    country: string | null;
    postal_code: string | null;
    lat: number | null;
    lon: number | null;
    region: string | null;
    store_number: string | null;
}

interface TransactionState {
    link_token: string | null;
    hostedLink: string | null;
    linkStatus: string;
    salaryDetails: null;
    postSalaryDetails: null;

    salaryDetailsStatus: string;
    postSalaryDetailsStatus: string;

    error: string | null;
    publicAccessToken: string | null;
    publicAccessTokenStatus: string;
    accounts: AccountInfo[] | null;
    accountStatus: string | null;
    transactions: Transaction[] | null;
    spendingState: SpendingState
}

interface Institution {
    id: number;
    logo_content: string | null;
    institution_name: string;
    institution_id: string;
    url: string;
}

interface AccountInfo {
    id: number;
    account_id: string;
    item_id: string;
    institution: Institution;
    persistent_account_id: string;
    name: string;
    official_name: string;
    mask: string;
    subtype: string;
    type: string;
    available_balance: number;
    current_balance: number;
    iso_currency_code: string;
    balance_limit: number | null;
    unofficial_currency_code: string | null;
    user_id: string;
    created_at: string;
    updated_at: string;

}
interface SpendingBreakDownItem {
    categoryType: string;
    finleyCategoryName: string;
    finleyCategory: string;
    amount: string;
}

interface SpendingState {
    status: string;
    monthlyAvgSpending: number | null;
    monthlyAvgIncome: number | null;
    spendingBreakDown: SpendingBreakDownItem[];
}

const initialSpendingState: SpendingState = {
    status: STATUS.IDLE,
    monthlyAvgSpending: null,
    monthlyAvgIncome: null,
    spendingBreakDown: [
        {
            categoryType: "",
            finleyCategoryName: "",
            finleyCategory: "",
            amount: '0',
        },
    ],
};

const initialState: TransactionState = {
    link_token: null,
    hostedLink: null,
    salaryDetails: null,
    postSalaryDetails: null,
    salaryDetailsStatus: STATUS.IDLE,
    postSalaryDetailsStatus: STATUS.IDLE,
    linkStatus: STATUS.IDLE,
    error: null,
    publicAccessToken: null,
    publicAccessTokenStatus: STATUS.IDLE,
    accounts: null,
    accountStatus: STATUS.IDLE,
    transactions: null,
    spendingState: initialSpendingState,
};
const TransactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLinkTokenAsync.pending, (state) => {
                state.linkStatus = STATUS.LOADING;
            })
            .addCase(fetchLinkTokenAsync.fulfilled, (state, action) => {
                state.linkStatus = STATUS.SUCCESS;
                state.link_token = action.payload.link_token || null;
                state.hostedLink = action.payload.hosted_link_url || null;
                state.error = null; // Clear any previous error
            })
            .addCase(fetchLinkTokenAsync.rejected, (state, action) => {
                state.linkStatus = STATUS.FAILED;
                state.error = action.payload as string || null;
            })
            .addCase(fetchPublicAccessTokenAsync.pending, (state) => {
                state.publicAccessTokenStatus = STATUS.LOADING;
            })
            .addCase(fetchPublicAccessTokenAsync.fulfilled, (state, action) => {
                state.publicAccessTokenStatus = STATUS.SUCCESS;
                state.publicAccessToken = action.payload.link_token || null;
                state.error = null; // Clear any previous error
            })
            .addCase(fetchPublicAccessTokenAsync.rejected, (state, action) => {
                state.publicAccessTokenStatus = STATUS.FAILED;
                state.error = action.payload as string || null;
            })
            .addCase(fetchAccountDetailAsync.pending, (state) => {
                state.accountStatus = STATUS.LOADING;
            })
            .addCase(fetchAccountDetailAsync.fulfilled, (state, action) => {
                state.accountStatus = STATUS.SUCCESS;
                state.accounts = action.payload;
                state.error = null; // Clear any previous error
            })
            .addCase(fetchAccountDetailAsync.rejected, (state, action) => {
                state.accountStatus = STATUS.FAILED;
                state.error = action.payload as string || null;
            })
            .addCase(fetchAllTransactionsAsync.fulfilled, (state, action) => {

                state.transactions = action.payload || null;
                state.error = null; // Clear any previous error
            })
            .addCase(fetchAllTransactionsAsync.rejected, (state, action) => {
                state.spendingState.status = STATUS.FAILED;
                state.error = action.payload as string || null;
            })
            .addCase(fetchAllTransactionsAsync.pending, (state) => {
                state.accountStatus = STATUS.LOADING;
            })
            .addCase(fetchAnalyzeDataAsync.fulfilled, (state, action) => {
                state.spendingState = action.payload || null;
                state.spendingState.status = STATUS.SUCCESS;
                state.error = null; // Clear any previous error
            })
            .addCase(fetchAnalyzeDataAsync.rejected, (state, action) => {
                state.spendingState.status = STATUS.FAILED;
                state.error = action.payload as string || null;
            })
            .addCase(fetchAnalyzeDataAsync.pending, (state) => {
                state.spendingState.status = STATUS.LOADING;
            })
            .addCase(fetchSalaryAsync.fulfilled, (state, action) => {
                state.salaryDetailsStatus = STATUS.SUCCESS;
                state.salaryDetails = action.payload || null;

            })
            .addCase(fetchSalaryAsync.rejected, (state, action) => {
                state.salaryDetailsStatus = STATUS.FAILED;
                state.error = action.payload as string || null;
            })
            .addCase(fetchSalaryAsync.pending, (state) => {
                state.salaryDetailsStatus = STATUS.LOADING;
                state.accountStatus = STATUS.LOADING;
            })
            .addCase(postSalaryAsync.fulfilled, (state, action) => {
                state.postSalaryDetailsStatus = STATUS.SUCCESS;
                state.postSalaryDetails = action.payload || null;

            })
            .addCase(postSalaryAsync.rejected, (state, action) => {
                state.postSalaryDetailsStatus = STATUS.FAILED;
                state.error = action.payload as string || null;
            })
            .addCase(postSalaryAsync.pending, (state) => {
                state.postSalaryDetailsStatus = STATUS.LOADING;
                state.accountStatus = STATUS.LOADING;
            })
            ;
    },
});

export default TransactionSlice.reducer;
