'use client'

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';

interface AlertMessageProps {
  open: boolean; // New prop to control visibility
  title: string;
  message: string;
  type: 'success' | 'error';
  onClose: () => void;
}

const AlertMessage: React.FC<AlertMessageProps> = ({ open, title, message, type, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl">
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
                {type==='error' ? <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />: <CheckIcon aria-hidden="true" className='text-green-600' />}
               
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                  {title}
                </DialogTitle>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{message}</p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <button
                type="button"
                onClick={onClose}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
              >
                Close
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default AlertMessage;
