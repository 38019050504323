import React, {  forwardRef, useEffect, useState } from 'react';
import Drawer from '../../common/Drawer'
import { TERMS_AND_PRIVACY } from '../../../types/CommonType';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { saveAccountAndAcknowledge } from '../../../store/thunks/OnBoardingThunk';
import { AccountCreationStatus, updateAcknowledgeDate, validateStep } from '../../../store/slices/OnBoardingSlice';
import { MESSAGES } from '../../../types/CommonType';
import MessageComponent from '../../common/MessageComponent';
import { useNavigate } from "react-router";

const Step4 = forwardRef<HTMLDivElement>((props, ref) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [drawerContent, setDrawerContent] = useState<{ title: string; content: string } | null>(null);
    const phone = useSelector((state: RootState) => state.OnboardingStore.phone);
    const password = useSelector((state: RootState) => state.OnboardingStore.password);
    const accountCreationStatus = useSelector((state: RootState) => state.OnboardingStore.accountCreationStatus);
    const userOnboarding = useSelector((state: RootState) => state.OnboardingStore.userOnboarding);
    const [isVerifying, setIsVerifying] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(validateStep({ step: 'step4', isValid: true }));
    }, [])
    
    const handleOpenDrawer = (type: 'terms' | 'privacy') => {
        const selectedContent = type === 'terms' ? TERMS_AND_PRIVACY.terms : TERMS_AND_PRIVACY.privacy;
        setDrawerContent(selectedContent);
        setIsDrawerOpen(true);
    };

    const updateAcknowledgementDate = async (field: string) => {

        if (field === 'termsDate') {
            dispatch(updateAcknowledgeDate({ field: 'termsDate', value: new Date() }));
        } else {
            dispatch(updateAcknowledgeDate({ field: 'privacypolicyDate', value: new Date() }));
        }
    }
// useEffect(() => {
//   if (acknowledge.privacypolicyDate && acknowledge.termsDate)
//   {

//   }
//   else
//   {
//     setError('Please confirm')
//   }
  
// }, [acknowledge])

    useEffect(() => {
        if (isVerifying) {
            if (accountCreationStatus === AccountCreationStatus.SUCCESS) {
                setIsVerifying(false);
                const accessToken: string | undefined = userOnboarding?.accessToken;
                localStorage.setItem('accessToken', accessToken as string);
                navigate('/connect-account')
            } else if (accountCreationStatus === AccountCreationStatus.FAILED) {
                setIsVerifying(false);
                setError(MESSAGES.ACCOUNT_CREATION_FAILED);
            }
        }

    }, [accountCreationStatus, isVerifying, userOnboarding])

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false);
        setDrawerContent(null); // Clear content when closing
    };

    const handleAccountCreation = async () => {
        setError('');
        setIsVerifying(true);
        try {
            const payload = { phoneNumber: phone, password: password};
            const result = await dispatch(saveAccountAndAcknowledge(payload));
        
            console.log('Account and Acknowledge succeeded:', result);
          } catch (error) {
            console.error('Error saving account or acknowledging:', error);
          }

    }
    return (
        <>
            <div className='rounded-2xl h-[226px] bg-white border-slate-200 p-[25px] border pt-4' >
            <div ref={ref}></div>
            <div>
                    <h2 className="text-neutral-900 font-serif body-medium mb-2">
                        Finley always protects your financial information.
                    </h2>
                    <ul className="list-disc pl-6 text-gray-800">
                        <li>Bank-level data encryption</li>
                        <li>Zero visibility or access given to your employer</li>
                    </ul>
                </div>

                <div className="flex flex-col text4 pt-4" >
                    <p>By clicking “Acknowledge” you agree to the following {' '}
                        <button
                            onClick={() => {
                                updateAcknowledgementDate('termsDate');
                                handleOpenDrawer('terms');
                            }}
                            className="text-blue-600 hover:underline"
                        >
                            Terms
                        </button>{' and '}
                        <button
                            onClick={() => {
                                updateAcknowledgementDate('privacypolicyDate');
                                handleOpenDrawer('privacy');
                            }}
                            className="text-blue-600 hover:underline"
                        >
                            Privacy Policy.
                        </button>
                    </p>
                   
                </div>
            </div>
           
            <div className="w-full pt-2">
                <button className="flex justify-center items-center cursor-pointer w-full bg-loginBg text3 text-white py-2 rounded-full" onClick={handleAccountCreation} >
                Acknowledge & Create My Account
                </button>
            </div>
            {error && <div className='pt-2'>
                <MessageComponent type="Error" message={error}></MessageComponent>
            </div>}
            {isDrawerOpen && drawerContent && (
              <Drawer
              isOpen={isDrawerOpen}
              onClose={handleCloseDrawer}
              title={drawerContent?.title || ''} 
              content={drawerContent?.content || ''} 
          />
            )}
            

        </>
    );
});

export default Step4;

